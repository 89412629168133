<template>
    <div>
        <v-app>
            <div class="main-web-header">
                <!-- Header Segment -->
                <common-home-header :callpermissionflag="callpermissionflag"></common-home-header>
                <v-divider></v-divider>

                <!-- Header Tabs Segment -->
                <header-tabs-component :tab_id="1" :is_user_logged_in="isUserLoggedIn ? isUserLoggedIn : false"></header-tabs-component>
                <v-divider></v-divider>
            </div>

            <!-- Categories Header -->
            <!-- <category-header-web v-if="!skeletonLoaderFlag" @handleComponentSizeChange="handleComponentSizeChange" :key="categoryHeaderComponentKey" class="header-web-component" heading_name="Categories"></category-header-web> -->
            <category-header-mobile @refreshData="refreshData" class="header-mobile-component" page_name="All Categories" :icon_show_status="iconStatusProp" :sortValue="sortByValue"></category-header-mobile>
            <v-divider></v-divider>

            <div class="grey-background">
                <!-- Breadcrumbs -->
                <div class="breadcrumbs-segment">
                    <v-breadcrumbs :items="breadcrumbs" class="body-text font-weight-medium">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                <span v-if="item != breadcrumbs[breadcrumbs.length-1]" class="body-text font-weight-medium BDBDBD-grey-color-text dark-grey-text-on-hover">{{ item.text }}</span>
                                <span v-else class="body-text font-weight-medium dark-grey-color-text">{{ item.text }}</span>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </div>

                <!-- Loader Web -->
                <div v-if="skeletonLoaderFlag" class="loader-web">
                    <v-skeleton-loader elevation="0" type="image" height="120" class="mb-6"></v-skeleton-loader>

                    <div class="container mb-6">
                        <div class="pa-6 rounded-lg grey-background">
                            <v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

                            <div class="pt-6 pb-2 d-flex flex-wrap">
                                <div class="pa-2">
                                    <v-skeleton-loader elevation="0" type="image" width="320" height="150"></v-skeleton-loader>
                                </div>

                                <div class="pa-2">
                                    <v-skeleton-loader elevation="0" type="image" width="320" height="150"></v-skeleton-loader>
                                </div>

                                <div class="pa-2">
                                    <v-skeleton-loader elevation="0" type="image" width="320" height="150"></v-skeleton-loader>
                                </div>
                                
                                <div class="pa-2">
                                    <v-skeleton-loader elevation="0" type="image" width="320" height="150"></v-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <v-divider></v-divider>
                </div>

                <!-- Loader Mobile -->
                <div v-if="skeletonLoaderFlag" class="loader-mobile">
                    <div class="pa-4">
                        <div class="py-2">
                            <div class="py-2">
                                <v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
                            </div>

                            <div class="py-2">
                                <v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
                            </div>

                            <div class="py-2">
                                <v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
                            </div>

                            <div class="py-2">
                                <v-skeleton-loader elevation="0" type="image"></v-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!skeletonLoaderFlag" class="container all-categories-web-component">
                    <div class="pa-4 pa-sm-8 pa-lg-10 pt-sm-0">
                        <div class="rounded-lg white-background">
                            <div class="pb-4 d-flex justify-space-between">
                                <p class="ma-0 body-heading font-weight-semi-bold pa-4">{{ $t("Customer.AllCategoriesPage.All_Categories") }}</p>

                                <div>
                                    <div class="ma-4 px-2 py-1 rounded d-flex align-center light-blue-tint-background clickable-pointer" @click="sortByPopup=true">
                                        <img class="sortby-icon-class" src="https://s3iconimages.mymedicine.com.mm/sort_by_icon_primary_light_no_bg.svg" alt="sortby-icon">
                                        <p class="ma-0 pl-2 body-text font-weight-medium light-blue-color-text">{{ $t("Customer.AllCategoriesPage.Sort") }}</p>
                                    </div>
                                    
                                    <div class="sort-container">
                                        <div v-if="sortByPopup" class="pa-4 white-background rounded-lg light-grey-border sortby-popup">
                                            <div class="d-flex justify-space-between align-center">
                                                <p class="ma-0 body-heading font-weight-semi-bold">{{ $t("Customer.AllCategoriesPage.Sort_By") }}</p>

                                                <img class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/cancel_icon_black.svg" alt="Close" @click="sortByPopupClose(sortByValue)">
                                            </div>

                                            <v-divider class="my-2"></v-divider>

                                            <div class="d-flex-column align-center sort-options-container">
                                                <div class="pa-2 d-flex justify-space-between align-center clickable-pointer" @click="sortByPopupClose('AToZ')">
                                                    <p class="ma-0 body-text font-weight-medium" :class="[sortByValue == 'AToZ' ? 'light-blue-color-text' : 'black-color-text']">{{ $t("Customer.AllCategoriesPage.A-Z") }}</p>

                                                    <img v-if="sortByValue == 'AToZ'" src="https://s3iconimages.mymedicine.com.mm/select_button_blue.svg" alt="Selected">
                                                </div>

                                                <div class="pa-2 d-flex justify-space-between align-center clickable-pointer" @click="sortByPopupClose('ZToA')">
                                                    <p class="ma-0 body-text font-weight-medium" :class="[sortByValue == 'ZToA' ? 'light-blue-color-text' : 'black-color-text']">{{ $t("Customer.AllCategoriesPage.Z-A") }}</p>

                                                    <img v-if="sortByValue == 'ZToA'" src="https://s3iconimages.mymedicine.com.mm/select_button_blue.svg" alt="Selected">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="categories.length > 0">
                                <all-categories-component @handleComponentSizeChange="handleComponentSizeChange" :categories="categories"></all-categories-component>
                            </div>
                            <div v-else class="d-flex justify-center align-center no-categories-found-segment">
                                <p class="ma-0 body-heading font-weight-bold">{{ $t("Customer.AllCategoriesPage.No_Categories_found") }}</p>
                            </div>

                            <div v-if="exclusiveStartKey == null" class="pb-4">
                                <button class="px-4 py-2 rounded-lg light-blue-background white-color-text" @click="loadMore()">{{ $t("Customer.AllCategoriesPage.Load_More") }}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!skeletonLoaderFlag" class="pt-4 container white-background all-categories-mobile-component">
                    <div v-if="categories.length > 0">
                        <all-categories-component @handleComponentSizeChange="handleComponentSizeChange" :categories="categories"></all-categories-component>
                    </div>
                    <div v-else class="d-flex justify-center align-center no-categories-found-segment">
                        <p class="ma-0 body-heading font-weight-bold">{{ $t("Customer.AllCategoriesPage.No_Categories_found") }}</p>
                    </div>

                    <div v-if="exclusiveStartKey == null" class="pt-4">
                        <button class="px-4 py-2 rounded-lg light-blue-background white-color-text" @click="loadMore()">{{ $t("Customer.AllCategoriesPage.Load_More") }}</button>
                    </div>
                </div>
            </div>

            <!-- Footer (Web) -->
            <div class="pa-4 footer-segment">
                <common-home-footer></common-home-footer>
            </div>

            <!-- Bottom Navigation Bar (Mobile) -->
            <div class="bottom-navbar-segment bottom-navigation-component">
                <bottom-navigation-bar highlight_icon="pharmacy"></bottom-navigation-bar>
            </div>
        </v-app>
    </div>
</template>

<script>
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils';
import axios from "axios";
export default {
    name: 'AllCategoriesPage',
    components: {
        'common-home-header': () => import('../../../components/navigationComponents/commonHomeHeader.vue'),
        'header-tabs-component': () => import('../../../components/navigationComponents/headerTabsComponent.vue'),
        'category-header-web': () => import('../../../components/navigationComponents/categoryHeaderWeb.vue'),
        'category-header-mobile': () => import('../../../components/navigationComponents/categoryHeaderMobile.vue'),
        'all-categories-component': () => import('../../../components/ePharmacyComponents/allCategoriesComponent.vue'),
        'price-range-segment': () => import('../../../components/ePharmacyComponents/priceRangeComponent.vue'),
        'bottom-navigation-bar': () => import('../../../components/navigationComponents/bottomNavigationBar.vue'),
        'common-home-footer': () => import('../../../components/navigationComponents/commonHomeFooter.vue'),
    },
    data() {
        return {
            skeletonLoaderFlag: true,
            isUserLoggedIn: false,
            breadcrumbs: [
                {
                    text: 'HOME',
                    disabled: false,
                    href: window.location.origin + '/'
                },
                {
                    text: 'PHARMACY',
                    disabled: false,
                    href: window.location.origin + '/customer/epharmacyHome'
                },
                {
                    text: 'CATEGORIES',
                    disabled: true,
                    href: window.location.origin + '/customer/allCategoriesPage'
                },
            ],
            categories: [],
            exclusiveStartKey: null,
            iconStatusProp: { sort_icon: true, filter_icon: false },
            categoryHeaderComponentKey: 0,
            categoryHeaderMobileComponentKey: 0,
            sortByPopup: false,
            sortByValue: 'AToZ',
            currentSortOrder: 'ASCENDING',
            callpermissionflag: false,
            token: null
        }
    },
    async mounted() {
        if (window.xm){
      		document.title = '';
            this.token = localStorage.getItem('customerToken');
    	} else {
			document.title = 'Categories';
            this.token = this.$cookies.get('customerToken');
		}
        try{
            // Fetch all categories data.
            let allCategoriesRequest = {
                sortOrder: this.currentSortOrder,
                exclusiveStartKey: ''
            };
            await this.getAllCategories(allCategoriesRequest);
            this.callpermissionflag=true;
        } catch(error){
            console.error("[INFO] Error ocurred",error);
        }
        this.userPageViewTable();
    },
    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'allCategoriesPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'allCategoriesPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        handleComponentSizeChange() {
            console.log('handleComponentSizeChange called');
            this.categoryHeaderComponentKey++;
        },
        getAllCategories(getAllCategoriesRequest){
            axios_auth_instance_epharmacy_customer.get('/pagewise/allCategories', { params: getAllCategoriesRequest }).then((allCategoriesResponse) => {
                // Check Login.
                this.isUserLoggedIn = allCategoriesResponse.data.authenticationStatus == 401 ? false : true;

                let categories_list = allCategoriesResponse.data.data;
                this.exclusiveStartKey = allCategoriesResponse.data.exclusiveStartKey ? JSON.stringify(allCategoriesResponse.data.exclusiveStartKey) : '';

                this.categories = [];
                categories_list.forEach((category) => {
                    this.categories.push({
                        id: category.cat_id,
                        name: category.cat_name,
                        cat_image_mobile: category.cat_image_mobile,
                        cat_image_tablet: category.cat_image_tablet,
                        cat_image_web: category.cat_image_web
                    });
                });

                this.skeletonLoaderFlag = false;
            }).catch((allCategoriesError) => {
                console.log('Error fetching all categories page data: ', allCategoriesError);
                this.error_enable = true;
            });
        },
        sortByPopupClose(sortByValue) {
            this.skeletonLoaderFlag = true;
            this.sortByValue = sortByValue;

            let allCategoriesRequest;
            
            if (this.sortByValue === 'AToZ') {
                this.currentSortOrder = 'ASCENDING';
                allCategoriesRequest = {
                    sortOrder: this.currentSortOrder,
                    exclusiveStartKey: this.exclusiveStartKey
                };
            } else {
                this.currentSortOrder = 'DESCENDING';
                allCategoriesRequest = {
                    sortOrder: this.currentSortOrder,
                    exclusiveStartKey: this.exclusiveStartKey
                };
            }

            // Sort API.
            this.getAllCategories(allCategoriesRequest);

            this.categoryHeaderMobileComponentKey++;

            this.sortByPopup = false;
            this.skeletonLoaderFlag = false;
        },
        refreshData(sortOrder) {
            let allCategoriesRequest;

            if (sortOrder.sortValue === 'AToZ') {
                this.currentSortOrder = 'ASCENDING';
                allCategoriesRequest = {
                    sortOrder: this.currentSortOrder,
                    exclusiveStartKey: ''
                };
            } else {
                this.currentSortOrder = 'DESCENDING';
                allCategoriesRequest = {
                    sortOrder: this.currentSortOrder,
                    exclusiveStartKey: ''
                };
            }

            // Sort API.
            this.getAllCategories(allCategoriesRequest);
        },
        loadMore() {
            let allCategoriesRequest = {
                sortOrder: this.currentSortOrder,
                exclusiveStartKey: this.exclusiveStartKey
            };

            this.getAllCategories(allCategoriesRequest);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';
.sortby-icon-class {
    size: 16px;
}
.sortby-popup {
    z-index: 9999;
    position: absolute;
    width: 300px;
    top: 0px;
    right: 0;
}

.sort-container {
    position: relative;
}
.loader-web, .main-web-header, .header-web-component, .breadcrumbs-segment, .all-categories-web-component, .footer-segment {
    @media (max-width: 600px) {
        display: none !important;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        
    }

    @media (min-width: 1265px) {
        
    }
}
.loader-mobile, .header-mobile-component, .all-categories-mobile-component, .bottom-navigation-component {
    @media (max-width: 600px) {

    }

    @media (min-width: 601px) and (max-width: 1264px) {
        display: none !important;
    }

    @media (min-width: 1265px) {
        display: none !important;
    }
}
.no-categories-found-segment {
    min-height: 20vh;
}
</style>

<style lang="scss">
@import '../../../scss/classes.scss';
</style>